@mixin preventSelect {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}

@mixin textFieldInput {
	color: white !important;
	border: 0.01px solid #404040;
	padding: 1rem 1rem 1rem 1.5rem !important;
	font-size: 1.4rem !important;
	font-family: Poppins !important;
	line-height: 1.6 !important;
	border-radius: 40px;
	margin-bottom: 1rem;
	background-color: #000000;

	svg {
		position: absolute;
		color: $neon-yellow;
		right: 2rem !important;
		top: -9px !important;
		font-size: 3rem;
	}

	// .MuiAutocomplete-endAdornment {
	// 	color: green !important;
	// 	position: relative !important;
	// }
}

@mixin textFieldInputStartAdornment {
	color: white !important;
	border: 0.01px solid #404040;
	padding: 1rem 1rem 1rem 1.5rem !important;
	font-size: 1.4rem !important;
	font-family: Poppins !important;
	line-height: 1.6 !important;
	border-radius: 40px;
	margin-bottom: 1rem;
	background-color: #000000;
}
