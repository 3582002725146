@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.root {
	// min-height: calc(100vh);
	max-width: 100%;
	//padding: 20px 30px;
	//margin-left: calc(260px); // 20 is the padding for the sidebar
	background-color: $black-color;
	//max-height: 100vh;
	overflow-y: auto;
	//display: block;
}
.signinRoot {
	min-height: 100vh;
	display: flex;
}

.panel {
	min-height: 100vh;
	background-color: $black-color;
	//width: 34%;
	width: auto;
	z-index: 1;

	animation: fadeInAnimation ease 2.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	// @media (max-width: 991px) {
	// 	& {
	// 		width: 100%;
	// 	}
	// }

	@keyframes fadeInAnimation {
		0% {
			opacity: 0;
			transform: translateX(-40px);
		}
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.content {
	// margin-top: 35%;
	// padding: 10px 100px;
	margin-top: calc(50vh - 150px);
	padding: 0 2vw;
	display: flex;
	justify-content: center;
}

.w-100 {
	width: 100%;
}

.LogoIconWrapper {
	cursor: pointer;
	margin-bottom: 20;
	@include preventSelect;
}

.Logo {
	width: 118.25;
	height: 55;
	&:hover {
		transform: rotate(360deg);
		transition: all 0.7s cubic-bezier(0.86, 0, 0.07, 1);
	}
}

.welcomeText {
	position: static;
	width: 327px;
	height: 72px;
	margin-top: 20px;
	font-family: Poppins;
	font-weight: 800;
	font-size: 24px;
	line-height: 36px;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;
}

.helpText {
	width: 340px;
	margin-top: 20px;
	font-family: Poppins;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	// display: flex;
	align-items: center;
	letter-spacing: 0.03em;
	// position: static;
}
.rightBGImage {
	width: 100%;
	// position: relative;
	// height: 100vh;
	//object-fit: cover;
	//object-position: center;
	background-image: url('../../assets/GlobeBG.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;

	animation: fadeInAnimation2 ease 2.5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;

	// @media (max-width: 991px) {
	// 	& {
	// 		width: 0%;
	// 	}
	// }

	@keyframes fadeInAnimation2 {
		0% {
			opacity: 0;
			transform: translateX(30px);
		}
		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.googleButtonWrapper {
	margin-top: 30px;
}

.googleButton {
	display: flex !important;
	color: #000000 !important;
	align-items: center !important;
	flex-direction: row !important;
	justify-content: space-around;
	position: static;
	width: 327px;
	height: 48px;
	border-radius: 48px !important;
	padding: 2px 24px 0px !important;
	font-family: Poppins !important;
	font-size: 14px !important;
	line-height: 24px !important;
}

.errorWrapper {
	margin-top: 20px;
	display: flex;
	//flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 16px;
	background: #ff355a;
	border-radius: 8px;
}
.errorMsg {
	margin-left: 10px;
	font-family: Poppins;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
}

.errorMsgTop {
	font-weight: bold;
	margin-bottom: 2px;
}

// .loader {
// 	height: 80px !important;

// }

._loading_overlay_spinner {
	// -webkit-animation: fadeinout 2s linear infinite;
	// animation: fadeinout 2s linear infinite;
	// animation-iteration-count: 15;

	// @-webkit-keyframes fadeinout {
	// 	0%,
	// 	100% {
	// 		opacity: 0;
	// 	}
	// 	50% {
	// 		opacity: 1;
	// 	}
	// }

	// @keyframes fadeinout {
	// 	0%,
	// 	100% {
	// 		opacity: 0;
	// 	}
	// 	50% {
	// 		opacity: 1;
	// 	}
	// }

	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;

	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
}

.loader {
	width: 20%;
	height: auto;
}
