.mt-5 {
	margin-top: 20px;
}

.mb-5 {
	margin-bottom: 20px;
}

.my-5 {
	@extend .mt-5, .mb-5;
}

.mt-4 {
	margin-top: 15px;
}

.mb-4 {
	margin-bottom: 15px;
}

.my-4 {
	@extend .mt-4, .mb-4;
}

.select-label-with-icon {
	display: flex;
	align-items: center;

	& svg {
		margin-right: 10px;
		margin-bottom: 3px;
	}
}
